<template>
  <div>
    <router-view />
    <div class="js-scroll">
      <ReturnToIndexBtn path="/case" />
    </div>
  </div>
</template>
<script>
import ReturnToIndexBtn from "@/components/ReturnToIndexBtn.vue";
import scrollAnimMixin from "../../mixins/scrollAnimMixin.js";

export default {
  components: {
    ReturnToIndexBtn,
  },
  data() {
    return {
      scrollAnims: [],
      imgNum: 0,
      loadedImgNum: 0,
    };
  },
  mixins: [scrollAnimMixin],
  head: {
    meta: [{ property: "og:type", content: "article" }],
  },
};
</script>
<style lang="scss" deep>
// caseごとのユニークなcssはユニークなクラスを付与するか、
// 各コンポーネントへグローバルで<style>を設定
.caseDetail {
  section {
    margin-top: 100px;
    &.caseDetail__env {
      margin-top: 120px;
    }
    h3 {
      font-size: 2.4rem;
      text-align: left;
      border-bottom: 1px solid #cec9c3;
      padding-bottom: 20px;
    }
  }
  &__mv {
    margin-top: 100px;
  }
  &__outline {
    h1 {
      font-size: 3rem;
      line-height: 1.45;
      margin-top: 50px;
      text-align: left;
    }
    &__txt {
      font-size: 1.6rem;
      line-height: 2.1;
      margin-top: 40px;
      letter-spacing: 0.05em;
      a {
        color: #ff9e16;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &__cate {
      margin-top: 38px;
      p {
        font-weight: bold;
      }
      ul {
        display: flex;
        margin-left: -15px;
        flex-wrap: wrap;
      }
    }
  }
  &__env {
    ul {
      margin-top: 40px;
      &.js-scroll {
        transform: translateY(50px);
      }
      li {
        + li {
          margin-top: 20px;
        }
        &::before {
          content: "・";
        }
      }
    }
  }
  &__note {
    &__txt {
      margin-top: 40px;
      line-height: 2;
    }
    &__img {
      margin-top: 40px;
    }
    &__link {
      width: 100%;
      text-align: center;
      margin: 40px 0 60px;
      a {
        color: $orColor;
        font-size: 2.2rem;
        font-weight: bold;
        border-bottom: 1px solid $orColor;
        padding-bottom: 5px;
        display: inline-block;
        transition: all 0.3s ease-in;
        &:hover {
          border-color: transparent;
          transition: all 0.3s ease-out;
        }
      }
    }
  }
  &__ex {
    margin-top: 100px;
    &__problem {
      margin-top: 40px;
      &__p,
      &__a {
        display: flex;
        .ttl,
        .txt {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .ttl {
          flex: 0 0 80px;
          padding: 12px 17px;
          color: #fff;
          font-weight: bold;
        }
        .txt {
          background: #f2f0ec;
          flex-flow: 1;
          padding: 12px 17px;
          align-items: flex-start;
          flex-grow: 1;
        }
      }
      &__p {
        position: relative;
        .ttl {
          background: #3e3a39;
        }
        &::after {
          content: "";
          position: absolute;
          background: url("/genbashiko/img/common/arrow.png");
          width: 26px;
          height: 45px;
          display: block;
          top: 100%;
          left: 30px;
        }
      }
      &__a {
        margin-top: 20px;
        background: #f2f0ec;
        margin-left: 60px;
        .ttl {
          background: $orColor;
        }
      }
      &__img {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: 60px;
        p {
          flex: 1 0 47%;
          + p {
            margin-left: 60px;
          }
        }
      }
      &__cap {
        margin: 30px 0 0 60px;
      }
      + .caseDetail__ex__problem {
        margin-top: 80px;
      }
    }
    &__download {
      width: 100%;
      text-align: center;
      margin: 40px 0 60px;
      a {
        color: $orColor;
        font-size: 2.2rem;
        font-weight: bold;
        border-bottom: 1px solid $orColor;
        padding-bottom: 5px;
        display: inline-block;
        transition: all 0.3s ease-in;
        &:hover {
          border-color: transparent;
          transition: all 0.3s ease-out;
        }
      }
    }
    &__movie {
      display: flex;
      justify-content: space-between;
      margin: 80px auto 100px;
      &.single {
        justify-content: center;
        .caseDetail__ex__movie__block {
          width: 80%;
        }
      }
      &__block {
        width: 49%;
      }
      &__ttl {
        text-align: left;
        font-size: 1.6rem;
      }
      &__iframeWrap {
        margin-top: 12px;
        text-align: center;
        position: relative;
        border: 1px solid #cec9c3;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        &::before {
          content: "";
          display: block;
          padding-top: 56.25%;
        }
      }
    }
  }
  &__support {
    margin-top: 100px;
    &__ttl {
      font-size: 2rem;
      margin-top: 40px;
      text-align: left;
    }
    &__txt {
      margin-top: 30px;
      line-height: 2.1;
    }
  }
}

@media only screen and (max-width: 768px) {
  .caseDetail {
    section {
      margin-top: 60px;
      &.caseDetail__outline {
        margin-top: 25px;
      }
      &.caseDetail__env {
        margin-top: 60px;
      }
      h3 {
        font-size: 1.4rem;
      }
    }
    &__mv {
      margin-top: 50px;
    }
    &__outline {
      margin-top: 25px;
      h1 {
        font-size: 1.6rem;
        margin-top: 0;
      }
      &__txt {
        font-size: 1.2rem;
        margin-top: 25px;
        letter-spacing: 0;
      }
      &__cate {
        margin-top: 20px;
        > p {
          font-size: 1.2rem;
        }
        ul {
          flex-wrap: wrap;
        }
      }
    }
    &__env {
      h3 {
        padding-bottom: 15px;
      }
      ul {
        margin-top: 25px;
        font-size: 1.1rem;
        li {
          + li {
            margin-top: 5px;
          }
        }
      }
    }
    &__note {
      &__txt {
        padding-bottom: 15px;
        margin-top: 25px;
        font-size: 1.1rem;
      }
      &__img {
        margin-top: 20px;
      }
      &__link {
        margin: 20px 0 40px;
        a {
          font-size: 1.3rem;
        }
      }
    }
    &__ex {
      &__problem {
        margin-top: 25px;
        .ttl {
          font-size: 1.1rem;
          word-break: keep-all;
          flex: 0 0 50px;
        }
        .txt {
          font-size: 1.2rem;
          padding: 5px 10px;
          line-height: 1.45;
        }
        &__img {
          margin: 25px 0 0;
          p {
            &:nth-of-type(2n) {
              margin-left: 15px;
            }
          }
        }
        &__cap {
          margin: 20px 0 0;
          font-size: 1.1rem;
        }
        + .caseDetail__ex__problem {
          margin-top: 55px;
        }
      }
      &__download {
        margin: 20px 0 40px;
        a {
          font-size: 1.3rem;
        }
      }
      &__movie {
        display: block;
        margin: 50px auto 50px;
        &.single {
          .caseDetail__ex__movie__block {
            width: 100%;
          }
        }
        &__ttl {
          font-size: 1.2rem;
        }
        &__block {
          width: 100%;
          + .caseDetail__ex__movie__block {
            margin-top: 30px;
          }
        }
      }
    }
    &__support {
      margin-top: 30px;
      &__ttl {
        font-size: 1.3rem;
      }
      &__txt {
        font-size: 1.1rem;
        margin-top: 20px;
        line-height: 1.7;
        letter-spacing: 0;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.returnBtn {
  margin-top: 80px;
}
@media only screen and (max-width: 768px) {
  .returnBtn {
    margin-top: 40px;
  }
}
</style>
